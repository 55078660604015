import React, { useState, useEffect, useRef } from 'react';
import {
  Space,
  Row,
  Col,
  Button,
  Spin,
  Form,
  Input,
  Upload,
  message,
  Collapse,
  Select,
  notification,
  Modal,
  Radio,
  DatePicker,
  InputNumber,
} from 'antd';
import Icon, {
  RightOutlined,
  LoadingOutlined,
  EyeOutlined,
  ArrowLeftOutlined,
  UpOutlined,
  DownOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  FileTextOutlined,
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
// import DatePicker from "react-datepicker";
import axios from 'axios';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { BASE_URL, MASTER_DATA_URL, OIDC } from 'services/config';

import moment from 'moment';

import CustomQuill from 'components/organisms/CustomQuill';

import 'react-datepicker/dist/react-datepicker.css';
import { currencyFormatter, currencyParser, downloadFile } from 'utils';
import { PriorityProgram } from 'components/organisms/PriorityProgram';
import {
  CalenderIcon,
  DeleteIcon,
  SaveIcon,
  DownloadAbleIcon,
  UploadIcon,
  HomeLined,
} from 'components/atoms/SVG';
import { ReactComponent as Confirmation } from '../../../assets/logo/Confirmation.svg';
import { ProjectPriorityProgram } from 'components/organisms/ProjectPriorityProgram';
import LogActivityModal from 'components/modal/LogActivity';
import HistoryReviewModal from 'components/modal/HistoryReview';
import store from 'app/store';
import ProposalModal from 'components/modal/ProposalNew';

const EditProposalSubmission = () => {
  const params = useParams();
  let navigate = useNavigate();
  const { Dragger } = Upload;
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const requiredField = [{ required: true, message: 'Wajib Diisi' }];

  const [dataProposal, setDataProposal] = useState();
  const [loading, setLoading] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [disable, setDisable] = useState();
  const [isReject, setIsReject] = useState(false);

  const [receiver, setReceiver] = useState('');
  const receiverRef = useRef(receiver);

  const oidc_user = store.getState().oidc.oidc_user;
  const user = JSON.parse(oidc_user).profile;

  const [role, setRole] = useState();
  const [group, setGroup] = useState();

  let proposal = {};

  const [documents, setDocuments] = useState();
  const props = {
    name: 'file',
    multiple: true,
    action: 'https://bpldh-api-v2.groot.id/api/file-service/v1/files',
    beforeUpload: (file) => {
      const isTypeAllowed =
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/svg' ||
        file.type === 'image/png';
      if (!isTypeAllowed) {
        // message.error(`${file.name} is not a pdf file`);
        notification.error({
          message: 'Kesalahan',
          description: `Dokumen yang dapat di-upload adalah Dokumen PDF/JPG/JPEG/SVG/PNG`,
        });
      }

      const isSizeAllowed = file.size <= 100 * 1024 * 1024;
      if (!isSizeAllowed) {
        notification.error({
          message: 'Kesalahan',
          description: `Ukuran Dokumen yang di Upload Melebihi 100MB`,
        });
      }

      return (isTypeAllowed && isSizeAllowed) || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setDocuments(info.fileList);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const [listNegara, setListNegara] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKabupaten, setListKabupaten] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listKelurahan, setListKelurahan] = useState([]);
  const [listKodePos, setListKodePos] = useState([]);
  const [listJabatan, setListJabatan] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [defaultListUser, setDefaultListUser] = useState([]);
  const [listIC, setListIC] = useState([]);
  const [listFI, setListFI] = useState([]);
  const [listMA, setListMA] = useState([]);
  const [listMP, setListMP] = useState([]);
  const [listPM, setListPM] = useState([]);

  const loadListUser = () => {
    axios
      .get(`https://bpldh-api-v2.groot.id/api/user-management/v1/Employee/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];
          let arr2 = [];

          res.data?.data?.forEach((item) => {
            if (!arr2.some((i) => i.value === item.jabatan) && item.jabatan) {
              arr2.push({
                key: item.id,
                value: item.jabatan,
                label: item.jabatan,
              });
            }
            arr.push({
              ...item,
              key: item.id,
              value: item.name,
              label: item.name,
            });
          });

          setListJabatan(arr2);
          setDefaultListUser(arr);
          setListUser(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListNegara = () => {
    axios
      .get(`${MASTER_DATA_URL}/Negara/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaNegara,
              label: item.namaNegara,
            });
          });

          setListNegara(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListProvinsi = () => {
    axios
      .get(`${MASTER_DATA_URL}/Provinsi/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaProvinsi,
              label: item.namaProvinsi,
            });
          });

          setListProvinsi(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKabupatenKota = () => {
    axios
      .get(`${MASTER_DATA_URL}/KabupatenKota/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKabupatenKota,
              label: item.namaKabupatenKota,
            });
          });

          setListKabupaten(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKecamatan = () => {
    axios
      .get(`${MASTER_DATA_URL}/Kecamatan/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKecamatan,
              label: item.namaKecamatan,
            });
          });

          setListKecamatan(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKelurahan = () => {
    axios
      .get(`${MASTER_DATA_URL}/Kelurahan/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKelurahan,
              label: item.namaKelurahan,
            });
          });

          setListKelurahan(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKodePos = () => {
    axios
      .get(`${MASTER_DATA_URL}/KodePos/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.kodePos,
              label: item.kodePos,
            });
          });

          setListKodePos(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListIC = () => {
    axios
      .get(`${MASTER_DATA_URL}/InvestmentCriteria/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListIC(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListFI = () => {
    axios
      .get(`${MASTER_DATA_URL}/FinancialInstrument/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListFI(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListMA = () => {
    axios
      .get(`${MASTER_DATA_URL}/MekanismeAlokasi/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListMA(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListMP = () => {
    axios
      .get(`${MASTER_DATA_URL}/MekanismePenyaluran/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListMP(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListPM = () => {
    axios
      .get(`${MASTER_DATA_URL}/PenerimaManfaat/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListPM(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    loadDetailDataProposal();

    loadListNegara();
    loadListProvinsi();
    loadListKabupatenKota();
    loadListKecamatan();
    loadListKelurahan();
    loadListKodePos();
    loadListUser();
    loadListIC();
    loadListFI();
    loadListMA();
    loadListMP();
    loadListPM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleRole = (proposal) => {
    let _role = user?.Jabatan + '-' + user?.Directorat;

    if (user.Name === 'Superadmin Internal') {
      const queryParams = new URLSearchParams(window.location.search);
      _role = queryParams.get('role');
    } else {
      if (user?.JabatanCode === '101') {
        _role = 'direktur-utama';
      } else if (user?.DirectoratCode === '004') {
        if (user?.JabatanCode === '411') {
          _role = 'kadiv-ppd';
        } else if (user?.JabatanCode === '401') {
          if (proposal.currentReviewer?.group === 'director' || proposal.sendReviewTo === 'presidentDirector') {
            _role = 'director';
          } else {
            _role = 'donor';
          }
        } else {
          _role = 'staff-ppd';
        }
      } else if (user?.DirectoratCode && user?.DirectoratCode !== '-') {
        _role = 'direktorat-lain';
      } else {
        // if (user?.JabatanCode.includes('01')) {
        //   _role = 'direktur-kl';
        // } else {
        //   _role = 'staff-kl';
        // }
        if (user?.TypeUser === 'KL') {
          _role = 'direktur-kl';
        } else {
          _role = 'staff-kl';
        }
      }
    }

    setRole(_role);

    switch (_role) {
      case 'staff-ppd':
        if (
          (proposal.status === 'review' &&
            proposal.currentReviewer?.group === 'ppd') ||
          proposal.status === 'rejected' ||
          params.addendum
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'kadiv-ppd':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'division'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'staff-kl':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'kl'
        ) {
          if (
            proposal.review?.reviewers[0].finished === false &&
            proposal.review?.reviewers[0].group === 'kl'
          ) {
            setDisable(false);
          } else {
            setDisable(true);
          }
        } else {
          setDisable(true);
        }
        break;
      case 'direktur-kl':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'kl'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'direktorat-lain':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'directorate'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'director':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'director'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      case 'donor':
        if (
          proposal.status === 'review' &&
          proposal.currentReviewer?.group === 'donor'
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
        break;
      default:
        setDisable(false);
    }
  };

  const verifyUserAsReviewer = (proposal) => {
    if (!proposal?.reviewer?.length) {
      setDisable(true);
      return;
    }

    const currentStep = proposal.reviewer[0].step;
    const reviewers = proposal.reviewer;

    let isUserAsReviewer = false;
    
    if ([1].includes(currentStep)) {
      const userAsReviewer = reviewers.find((reviewer) => {
        return user.DirectoratCode === reviewer.directoratCode && user.HierarchyCode === reviewer.hierarchyCode  && user.DivisiCode === reviewer.divisiCode
      });
      isUserAsReviewer = !!userAsReviewer;
    }

    if ([3].includes(currentStep)) {
      const userAsReviewer = reviewers.find((reviewer) => {
        if (proposal.sendReviewTo === 'donor') {
          return user.DirectoratCode === reviewer.directoratCode && user.HierarchyCode === reviewer.hierarchyCode
        }

        if (proposal.sendReviewTo === 'kl') {
          return user.Directorat === reviewer.lembaga;
        }

        return user.DirectoratCode === reviewer.directoratCode && user.HierarchyCode === reviewer.hierarchyCode  && user.DivisiCode === reviewer.divisiCode
      });
      isUserAsReviewer = !!userAsReviewer;
    }

    if ([5].includes(currentStep)) {
      const userAsReviewer = reviewers.find((reviewer) => {
        if (proposal.sendReviewTo === 'directorate') {
          return user.Directorat === reviewer.lembaga
        }
        // sendReviewTo 'kl'
        return user.DirectoratCode === reviewer.directoratCode && user.HierarchyCode === reviewer.hierarchyCode;
      });
      isUserAsReviewer = !!userAsReviewer;
    }

    if ([2, 4, 6].includes(currentStep)) {
      const userAsReviewer = reviewers.find((reviewer) => {
        return user.DirectoratCode === reviewer.directoratCode && user.HierarchyCode === reviewer.hierarchyCode
      });
      isUserAsReviewer = !!userAsReviewer;
    }

    setDisable(!isUserAsReviewer);
    return;
  }

  useEffect(() => {
    console.log(role + '-' + group);
  }, [role, group]);

  const handleFunding = (data) => {
    switch (data.currency) {
      case 'IDR':
        return `Rp ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'EURO':
        return `€ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'USD':
        return `$ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      case 'JPY':
        return `¥ ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
      default:
        return `${data.currency} ${data.funding_total?.toLocaleString('id-ID', {
          maximumFractionDigits: 0,
        })}`;
    }
  };

  const loadDetailDataProposal = () => {
    axios
      .get(`${BASE_URL}/proposal/${params.id}?role=${role}&group=${group}`)
      .then((res) => {
        proposal = res.data.result;
        setLoading(false);
        if (
          !proposal?.generalInformation.objectives ||
          proposal?.generalInformation.objectives.length < 1
        ) {
          proposal.generalInformation.objectives = [''];
        }
        form.setFieldsValue({
          generalInformation: proposal.generalInformation,
        });
        form.setFieldsValue({ donor: proposal.donor });
        form.setFieldsValue({ director: proposal.director });
        form.setFieldsValue({ contactPerson: proposal.contactPerson });
        proposal.submissionFunding.date_period = dayjs(
          proposal.submissionFunding.date_period
        );
        proposal.submissionFunding.end_date_period = dayjs(
          proposal.submissionFunding.end_date_period
        );
        proposal.submissionFunding.funding = handleFunding(
          proposal.submissionFunding
        );
        proposal.submissionFunding.dp =
          moment(proposal?.submissionFunding?.date_period).format(
            'DD-MM-YYYY'
          ) +
          ' s/d ' +
          moment(proposal?.submissionFunding?.end_date_period).format(
            'DD-MM-YYYY'
          );
        form.setFieldsValue({ submissionFunding: proposal.submissionFunding });
        form.setFieldsValue({ distribution: proposal.distribution });

        proposal.priorityPrograms?.forEach((item, idx) => {
          if (proposal.priorityPrograms[idx].impact) {
            if (proposal.priorityPrograms[idx].impact?.indicators?.length < 1) {
              proposal.priorityPrograms[idx].impact.indicators = [{}];
            }
          } else {
            proposal.priorityPrograms[idx].impact = { indicators: [{}] };
          }

          proposal.priorityPrograms[idx].impact?.outcomes?.forEach(
            (outcome, outcomeIndex) => {
              if (outcome.indicators?.length < 1) {
                proposal.priorityPrograms[idx].impact.outcomes[
                  outcomeIndex
                ].indicators = [{}];
              }
              outcome?.outputs?.forEach((output) => {
                output?.subOutputs?.forEach((subOutput) => {
                  subOutput?.indicators?.forEach((indicator) => {
                    indicator?.parameters?.forEach((parameter) => {
                      let arr = [];

                      parameter?.indicativeActivities?.forEach((activity) => {
                        arr.push(activity.indicativeActivityName);
                      });

                      parameter._indicativeActivities = arr;
                    });
                  });
                });
              });
            }
          );
        });
        form.setFieldsValue({ priorityPrograms: proposal.priorityPrograms });

        if (
          proposal.projectPriorityPrograms &&
          proposal.projectPriorityPrograms.length > 0
        ) {
          form.setFieldsValue({
            projectPriorityPrograms: proposal.projectPriorityPrograms,
          });
        } else {
          form.setFieldsValue({
            projectPriorityPrograms: [{ outcomes: [{ outputs: [{}] }] }],
          });
        }
        form.setFieldsValue({
          projectPriorityPrograms: proposal.projectPriorityPrograms,
        });
        form.setFieldsValue({ klDirectorate: proposal.klDirectorate });
        form.setFieldsValue({
          investmentCriteria: proposal.investmentCriteria,
        });
        form.setFieldsValue({
          financialInstrument: proposal.financialInstrument,
        });
        form.setFieldsValue({
          organizationBackground: proposal.organizationBackground,
        });
        form.setFieldsValue({ needs: proposal.needs });
        form.setFieldsValue({
          organizationalLandscape: proposal.organizationalLandscape,
        });
        form.setFieldsValue({ principleObstacle: proposal.principleObstacle });
        form.setFieldsValue({
          communicatingResult: proposal.communicatingResult,
        });
        form.setFieldsValue({ projectStructure: proposal.projectStructure });
        form.setFieldsValue({ safeguards: proposal.safeguards });
        form.setFieldsValue({ others: proposal.others });

        form.setFieldsValue({ documents: proposal.documents });

        form.setFieldsValue({ addendum: proposal.addendum });
        form.setFieldsValue({ parentAddendumId: proposal.parentAddendumId });
        form.setFieldsValue({ status: proposal.status });
        form.setFieldsValue({ review: proposal.review });
        form.setFieldsValue({ reviewers: proposal.reviewer });
        form.setFieldsValue({ draft: proposal.draft });

        setDataProposal(form.getFieldsValue(true));

        handleRole(proposal);
        verifyUserAsReviewer(proposal);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Kesalahan',
          description: err.message,
        });
      });
  };

  const handleDeleteDocument = (idx) => {
    Modal.confirm({
      centered: true,
      title: '',
      icon: <Confirmation />,
      okText: 'Confirm',
      bodyStyle: { justifyContent: 'center' },
      content: (
        <>
          <h1
            className="mb-4"
            style={{
              fontSize: '17px',
              fontWeight: '600',
              lineHeight: '24px',
              justifyContent: 'center',
            }}
          >
            Anda yakin menghapus dokumen ini?
          </h1>
        </>
      ),
      onOk() {
        let arr = dataProposal?.documents || [];
        if (idx > -1) {
          arr.splice(idx, 1);
        }

        setDataProposal({ ...dataProposal, documents: arr });
      },
    });
  };

  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalProposal, setModalProposal] = useState(false);
  const [logSection, setLogSection] = useState('');

  const buttonLogPanel = (section) => (
    <Button
      className="btn-default"
      type="default"
      onClick={(event) => {
        event.stopPropagation();

        setOpenModal(true);
        setLogSection(section);
      }}
    >
      Log Aktivitas
    </Button>
  );

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  function normalizePayload(formValues) {
    let data = JSON.parse(JSON.stringify(formValues));
    console.log(formValues);
    console.log(documents);

    const payload = {
      ...data,
      financialInstrument: data.financialInstrument,
      investmentCriteria: data.investmentCriteria,
      donor: data.donor,
      draft: data.draft,
    };

    let docs = [];
    if (documents) {
      documents.forEach((item) => {
        let doc = item.response?.data;
        if (doc) {
          docs.push({
            fileId: doc.id,
            fileName: doc.name,
            fileSize: doc.size,
            mimeType: doc.mimeType,
            path: doc.key,
          });
        }
      });
    }

    if (dataProposal?.documents) {
      dataProposal?.documents.forEach((item) => {
        docs.push({ ...item });
      });
    }

    payload.documents = [...docs];

    if (params.addendum) {
      payload.parentAddendumId = params.id;
    }

    return payload;
  }

  function onFinish(values) {
    const payload = normalizePayload(values);
    setLoading(true);

    let submissionPath = params?.addendum
      ? `${BASE_URL}/proposal/addendum`
      : `${BASE_URL}/proposal/${params.id}/update-review`;
    let submissionMethod = params?.addendum ? 'post' : 'put';

    axios({ method: submissionMethod, url: submissionPath, data: payload })
      .catch((err) => {
        let errorMessage;
        let errorMessages = [];

        if (
          err.response.data.errors === undefined ||
          err.response.data.errors.length === 0
        ) {
          errorMessage = err.response.data.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err.response.data.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage ? errorMessage : errorMessages,
        });
        setLoading(false);
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          notification.success({
            message: 'Data berhasil disimpan!',
            duration: 5,
            description: 'Data yang anda masukan sudah berhasil disimpan.',
          });
          return navigate('/proposal-submission');
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const onSubmitProposal = () => {
    const currentStep = dataProposal.reviewers[0].step;

    if (
      role === 'staff-kl' ||
      role === 'direktur-kl' ||
      role === 'direktorat-lain' ||
      (role === 'donor') ||
      (role === 'kadiv-ppd' && currentStep === 3) ||
      (role === 'director' && currentStep === 6)
    ) {
      onApproval();
    } else {
      Modal.confirm({
        title: '',
        okText: 'Confirm',
        icon: <Confirmation />,
        centered: true,
        bodyStyle: { justifyContent: 'center' },
        content: (
          <>
            <h1
              className=""
              style={{
                fontSize: '17px',
                fontWeight: '600',
                lineHeight: '24px',
                textTransform: 'capitalize',
              }}
            >
              Pilih Distribusi Submit
            </h1>
            <Radio.Group name="radiogroup" initia className="mt-4">
              {role === 'kadiv-ppd' ? (
                <Space direction="vertical">
                  <Radio onChange={onReceiverChange} value={'directorate'}>
                    Direktorat Lain
                  </Radio>
                  <Radio
                    onChange={onReceiverChange}
                    value={'presidentDirector'}
                  >
                    Direktur Perhimpunan dan Pengembangan Dana
                  </Radio>
                </Space>
              ) : (
                <Space direction="vertical">
                  <Radio onChange={onReceiverChange} value={'kl'}>
                    KL
                  </Radio>
                  <Radio onChange={onReceiverChange} value={'donor'}>
                    Donor
                  </Radio>
                </Space>
              )}
            </Radio.Group>
            <br />
          </>
        ),
        onOk() {
          onApproval();
        },
      });
    }
  };

  function onApprovalProposal(receiverValue) {
    setLoading(true);
    const payload = {
      approve: true,
      rejectReason: '',
    };

    let receiverVal = 'Direktorat PPD';
    if (receiverValue) {
      payload.sendReviewTo = receiverValue;

      if (receiverValue === 'presidentDirector') {
        receiverVal = 'Direktur Perhimpunan dan Pengembangan Dana';
      } else {
        if (role === 'kadiv-ppd') {
          receiverVal = 'Direktorat Lain';
        } else {
          receiverVal = 'KL';
        }
      }
    } else {
      receiverVal =
        receiverValue === 'presidentDirector'
          ? 'Direktur Perhimpunan dan Pengembangan Dana'
          : 'KL/Direktorat Lain';
    }

    let _role;
    let _group;

    if (
      role === 'direktur-ppd' ||
      role === 'direktur-kl' ||
      role === 'direktorat-lain'
    ) {
      _role = 'director';
    }

    if (role === 'kadiv-ppd') {
      _role = 'division';
      _group = 'division';
    }

    if (role === 'direktorat-lain') {
      _group = 'directorate';
    }

    axios
      .put(
        `${BASE_URL}/proposal/${params.id}/approval?role=${_role}&group=${_group}`,
        payload
      )
      .then((res) => {
        setLoading(false);
        if (res) {
          notification.success({
            message: `Proposal berhasil Dikirim Kepada ${receiverVal}`,
            duration: 5,
            description: 'Data yang anda masukan sudah berhasil direview.',
          });
          return navigate('/proposal-submission');
        }
      })
      .catch((err) => {
        notification.error({
          message: 'Kesalahan',
          duration: 2,
          description: err.response.data.message,
        });
        setLoading(false);
      });
  }

  function onReceiverChange(e) {
    setReceiver(e.target.value);
  }

  useEffect(() => {
    receiverRef.current = receiver;
  }, [receiver]);

  const onApproval = () => {
    Modal.confirm({
      title: '',
      icon: <Confirmation />,
      okText: 'Confirm',
      bodyStyle: { justifyContent: 'center' },
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: '17px', fontWeight: '600', lineHeight: '24px' }}
          >
            Apakah Anda yakin akan menyetujui Data ini?
          </h1>
          <br />
        </>
      ),
      onOk() {
        if (role === 'direktur-kl' || role === 'direktorat-lain') {
          onApprovalProposal(null);
        } else {
          onApprovalProposal(receiverRef.current);
        }
        Modal.destroyAll();
      },
    });
  };

  const onEditProposal = () => {
    setDisable(false);
  };

  const onRejectProposal = () => {
    setIsReject(true);
  };

  const closeModalRejectProposal = () => {
    setIsReject(false);
  };

  function onProposalRejected() {
    setLoading(true);
    const rejectReason = form.getFieldsValue('reason').reason;
    const payload = {
      approve: false,
      rejectReason: rejectReason,
    };

    if (!rejectReason) {
      notification.error({
        message: 'Kesalahan',
        duration: 5,
        description: 'Alasan ditolak wajib diisi',
      });
      setLoading(false);
    } else {
      if (role === 'direktur' || role === 'direktorat') {
        role = 'director';
      }

      if (group === 'lain') {
        group = 'otherDirectorate';
      }

      axios
        .put(
          `${BASE_URL}/proposal/${params.id}/approval?role=${role}&group=${group}`,
          payload
        )
        .then((res) => {
          setLoading(false);
          if (res) {
            notification.success({
              message: 'Proposal Berhasil Ditolak',
              duration: 5,
              description: 'Data yang anda masukan sudah berhasil ditolak.',
            });
          }
          form.resetFields();
          return navigate('/proposal-submission');
        })
        .catch((err) => {
          notification.error({
            message: 'Kesalahan',
            duration: 2,
            description: err.response.data.message,
          });
          setLoading(false);
        });
    }
  }

  const handleStatus = (value) => {
    switch (value?.status) {
      case 'review':
        let text = [];
        value?.review?.groups.forEach((group) => {
          text.push(
            <span
              className="px-4 py-1"
              style={{
                background: '#EEFFF4',
                color: '#01A24A',
                borderRadius: '15px',
              }}
            >
              {group}
            </span>
          );
        });
        return <>{text.map((item) => item)}</>;
      case 'draft':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Draft
          </span>
        );
      case 'rejected':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#FEF3F2',
              color: '#B42318',
              borderRadius: '15px',
            }}
          >
            Ditolak
          </span>
        );
      case 'approved':
        return (
          <span
            className="px-4 py-1"
            style={{
              background: '#EEFFF4',
              color: '#01A24A',
              borderRadius: '15px',
            }}
          >
            Disetujui
          </span>
        );
      default:
    }
  };

  const handleAdendum = () => {
    return navigate(
      `/proposal-submission/create-proposal/${params.id}/addendum`
    );
  };

  const maximumEndDate = () => {
    let sf = form.getFieldValue('submissionFunding');

    if (sf.grant_period && sf.grant_period_type) {
      if (sf.grant_period_type === 'days') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'day');
        return dayjs(max).format('YYYY-MM-DD');
      } else if (sf.grant_period_type === 'months') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'month');
        return dayjs(max).format('YYYY-MM-DD');
      } else {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'year');
        return dayjs(max).format('YYYY-MM-DD');
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <Form
          form={form}
          name="form-edit-proposal"
          onFinish={onFinish}
          initialValues={{
            submissionFunding: { grant_period_type: 'months' },
          }}
        >
          <div className="px-4" style={{ position: 'relative' }}>
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLined} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Kelola Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button
                  style={{
                    background: '#EEFFF4',
                    color: '#01A24A',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                  className="flex justify-center items-center mt-2"
                >
                  <span className="font-medium text-center">
                    {params.addendum
                      ? 'Create Proposal Adendum'
                      : 'Detail Proposal Submission'}
                  </span>
                </Button>
              </div>
            </div>
            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-heading">Informasi Donor</p>
              </div>
              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row className="gap-4" gutter={24}>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Nama Donor</p>
                      <span className="text-body">
                        {(dataProposal?.donor[0]
                          ? dataProposal?.donor[0].donor
                          : dataProposal?.donor.donor) || '-'}
                      </span>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Program Tematik</p>

                      <ul
                        className="text-body ml-4"
                        style={{ listStyleType: 'disc' }}
                      >
                        {dataProposal?.donor.sectors
                          ? dataProposal?.donor.sectors.map((sector) => (
                              <li>{sector.name}</li>
                            ))
                          : '-'}
                      </ul>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">No Telepon</p>
                      {dataProposal?.donor.phoneNumbers
                        ? dataProposal?.donor.phoneNumbers.map(
                            (phoneNumber) => (
                              <ul className="text-body">
                                <span className="text-body">
                                  {phoneNumber || '-'}
                                </span>
                              </ul>
                            )
                          )
                        : '-'}
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Email</p>
                      <ul className="text-body">
                        {dataProposal?.donor.email
                          ? dataProposal?.donor.email.map((email) => (
                              <ul className="text-body">
                                <span className="text-body">
                                  {email || '-'}
                                </span>
                              </ul>
                            ))
                          : '-'}
                      </ul>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div>
                      <p className="text-sub-heading">Website</p>
                      <span className="text-body">
                        {(dataProposal?.donor[0]
                          ? dataProposal?.donor[0].website
                          : dataProposal?.donor?.website) || '-'}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-6 mt-4" />
            </div>

            <div
              className="overflow-hidden mb-4 mt-2"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <Row>
                <Col span={15}>
                  <div className="flex gap-4 px-5 py-5">
                    <p className="text-heading">
                      Detail Proposal {dataProposal?.addendum && '(Adendum)'}
                    </p>

                    <div className="mt-1 mb-4">
                      {handleStatus(dataProposal)}
                    </div>
                  </div>
                </Col>
                <Col span={9}>
                  <div className="flex gap-4 justify-end items-center mr-4 mt-3">
                    {(role === 'staff-kl' || role === 'direktur-lain') &&
                    role !== 'staff-ppd' &&
                    role !== 'kadiv-ppd' &&
                    role !== 'direktur-kl' ? (
                      <div>
                        {disable && (
                          <Button
                            className="btn-default"
                            icon={<EditOutlined />}
                            onClick={onEditProposal}
                          >
                            Ubah
                          </Button>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {dataProposal?.status === 'approved' &&
                      !params.addendum && (
                        <Button
                          className="btn-default"
                          icon={<FileTextOutlined style={{ fontSize: 18 }} />}
                          onClick={handleAdendum}
                        >
                          Ajukan Adendum
                        </Button>
                      )}
                    <Button
                      className="btn-default"
                      onClick={(event) => {
                        event.stopPropagation();

                        setOpenModalHistory(true);
                      }}
                    >
                      Histori Review & Approval
                    </Button>
                    <Button
                      className="btn-approve button-style"
                      icon={<EyeOutlined style={{ fontSize: 18 }} />}
                      onClick={() => setModalProposal(true)}
                      disabled={dataProposal?.status !== 'approved'}
                    >
                      Lihat Proposal
                    </Button>
                  </div>
                </Col>
              </Row>
              {dataProposal?.addendum && (
                <Row>
                  <Col span={12}>
                    <div className="flex gap-4 px-5">
                      <div className="mt-1 mb-4">
                        Proposal Sebelumnya: &nbsp;
                        <span
                          className="px-4 py-1"
                          style={{
                            background: '#F2F4F7',
                            color: '#344054',
                            borderRadius: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            navigate(
                              `/proposal-submission/view-proposal/${dataProposal?.parentAddendumId}?role=${role}&group=${group}`
                            );
                          }}
                        >
                          {dataProposal?.parentAddendumId}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <hr />
              <div className="px-5 mt-4 mb-4">
                <Row className="gap-4" gutter={10}>
                  <Col span={8}>
                    <div className="mb-4">
                      <p className="text-sub-heading">
                        Kementerian Lembaga/Negara
                      </p>
                      <ul
                        className="text-body ml-4"
                        style={{ listStyleType: 'disc' }}
                      >
                        {dataProposal?.klDirectorate?.kl?.map((kl) => (
                          <li>{kl.klName}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-4">
                      <p className="text-sub-heading">Direktorat</p>
                      <ul
                        className="text-body ml-4"
                        style={{ listStyleType: 'disc' }}
                      >
                        {dataProposal?.klDirectorate?.directorate?.map(
                          (directorate) => (
                            <li>{directorate.directorateName}</li>
                          )
                        )}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="mb-6 mt-4" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Dokumen Pendukung"
                  key="1"
                  extra={buttonLogPanel('documents')}
                >
                  <div className="px-4">
                    <div className="flex mt-4 mb-4">
                      {dataProposal?.documents &&
                        dataProposal?.documents.map((doc, idx) => (
                          <div
                            className="flex justify-between mr-4"
                            style={{
                              border: '1px solid #EAECF0',
                              borderRadius: '12px',
                              padding: '16px',
                              width: '335px',
                              height: '96px',
                            }}
                          >
                            <div className="mr-4">
                              <Icon component={DownloadAbleIcon} />
                            </div>
                            <div className="row mr-10">
                              <p className="wrap-text bold">{doc.fileName} </p>{' '}
                              <span>({doc.fileSize}KB)</span>
                              <div className="flex justify-between">
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    window.open(doc.url, '_blank');
                                  }}
                                >
                                  Buka
                                </strong>
                                <strong
                                  style={{
                                    color: '#00BD52',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    downloadFile(doc.url, doc.fileName);
                                  }}
                                >
                                  Unduh
                                </strong>
                              </div>
                            </div>
                            <div
                              className="ml-4"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleDeleteDocument(idx);
                              }}
                            >
                              <Icon component={DeleteIcon} />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="mt-4">
                      <p
                        style={{
                          color: '#667085',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '28px',
                        }}
                      >
                        Upload Dokumen Pendukung
                      </p>
                      <span style={{ color: '#98A2B3', fontSize: '12px' }}>
                        *Bisa upload lebih dari satu
                      </span>
                    </div>
                    <div className="row mt-4 mb-4">
                      <Form.Item
                        name="documents"
                        initialValue="https://drive.google.com/user/image.png"
                      >
                        <Dragger {...props} disabled={disable}>
                          <p className="ant-upload-drag-icon flex justify-center">
                            <UploadIcon />
                          </p>
                          <p className="ant-upload-text">
                            <strong style={{ color: '#01A24A' }}>
                              Klik untuk upload
                            </strong>{' '}
                            atau drag and drop
                          </p>
                          <p className="ant-upload-hint">
                            PDF, SVG, PNG, JPG, JPEG, or GIF (max.100mb)
                          </p>
                        </Dragger>
                      </Form.Item>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Informasi General"
                  key="1"
                  extra={buttonLogPanel('generalInformation')}
                >
                  <div className="mt-2">
                    <div>
                      <div className="row px-2 mt-2">
                        <label className="field-required font-medium">
                          Judul Proposal
                        </label>
                        <Form.Item name={['generalInformation', 'title']}>
                          <Input
                            placeholder="Judul Proposal"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-2 mb-4">
                      <div className="">
                        <label className="field-required font-medium">
                          Objektif Proposal
                        </label>
                        <Form.Item
                          shouldUpdate
                          // rules={requiredField}
                        >
                          <Form.List
                            name={['generalInformation', 'objectives']}
                          >
                            {(objectives, { add, remove }) => (
                              <>
                                {objectives.map((objective, index) => {
                                  return (
                                    <Row gutter={12}>
                                      <Col span={disable ? 24 : 22}>
                                        <Form.Item name={[index]}>
                                          <Input
                                            placeholder="Objektif Proposal"
                                            type="text"
                                            className="form-input mt-2"
                                            disabled={disable}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {index > 0 && !disable && (
                                        <Col span={1}>
                                          <Button
                                            style={{
                                              width: '100%',
                                              height: '48',
                                            }}
                                            className="btn-default mt-2"
                                            onClick={() => remove(index)}
                                          >
                                            <DeleteOutlined />
                                          </Button>
                                        </Col>
                                      )}
                                      {index > objectives.length - 2 &&
                                        !disable && (
                                          <Col span={1}>
                                            <Button
                                              style={{
                                                width: '100%',
                                                height: '48px',
                                              }}
                                              className="btn-default mt-2"
                                              onClick={() => add()}
                                            >
                                              <PlusOutlined />
                                            </Button>
                                          </Col>
                                        )}
                                    </Row>
                                  );
                                })}
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-2">
                      <div className="">
                        <label className="field-required font-medium">
                          Nama Organisasi
                        </label>
                        <Form.Item
                          name={['generalInformation', 'organizationName']}
                        >
                          <Input
                            placeholder="Nama Organisasi"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-2">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Negara
                            </label>
                            <Form.Item name={['generalInformation', 'country']}>
                              <Select
                                placeholder="Negara"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listNegara}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Provinsi
                            </label>
                            <Form.Item
                              name={['generalInformation', 'province']}
                            >
                              <Select
                                placeholder="Provinsi"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listProvinsi}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kota/Kabupaten
                            </label>
                            <Form.Item name={['generalInformation', 'city']}>
                              <Select
                                placeholder="Kota/Kabupaten"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listKabupaten}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-2">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kecamatan
                            </label>
                            <Form.Item
                              name={['generalInformation', 'district']}
                            >
                              <Select
                                placeholder="Kecamatan"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listKecamatan}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kelurahan
                            </label>
                            <Form.Item
                              name={['generalInformation', 'subdistrict']}
                            >
                              <Select
                                placeholder="Kelurahan"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listKelurahan}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Kode Pos
                            </label>
                            <Form.Item name={['generalInformation', 'zipcode']}>
                              <Select
                                placeholder="Kode Pos"
                                type="text"
                                className="mt-2"
                                style={{ width: '100%' }}
                                options={listKodePos}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-2">
                      <div className="">
                        <label className="field-required font-medium">
                          Alamat Lengkap
                        </label>
                        <Form.Item name={['generalInformation', 'address']}>
                          <Input.TextArea
                            placeholder="Alamat Lengkap"
                            type="text"
                            className="form-input mt-2"
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-2 mt-4">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              No Telepon
                            </label>
                            <Form.Item
                              name={['generalInformation', 'phoneNumber']}
                            >
                              <Input
                                placeholder="No Telepon"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Email
                            </label>
                            <Form.Item name={['generalInformation', 'email']}>
                              <Input
                                placeholder="Email"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="font-medium">Fax</label>
                            <Form.Item name={['generalInformation', 'fax']}>
                              <Input
                                placeholder="Fax"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="row px-2 mt-4">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Website
                            </label>
                            <Form.Item name={['generalInformation', 'website']}>
                              <Input
                                placeholder="Website"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Informasi Direktur
                      </p>
                      <div className="row mt-4">
                        <Form.Item
                          shouldUpdate
                          // rules={requiredField}
                        >
                          <Form.List name={['director']}>
                            {(directors, { add, remove }) => (
                              <>
                                {directors.map((director, index) => {
                                  return (
                                    <Row gutter={12}>
                                      <Col span={22}>
                                        <Row gutter={24} className="">
                                          <Col span={8}>
                                            <div className="">
                                              <label className="field-required font-medium">
                                                Jabatan
                                              </label>
                                              <Form.Item
                                                name={[index, 'position']}
                                                rules={requiredField}
                                              >
                                                <Select
                                                  placeholder="Jabatan"
                                                  type="text"
                                                  className="mt-2"
                                                  style={{ width: '100%' }}
                                                  options={listJabatan}
                                                  disabled={disable}
                                                  onChange={(value) => {
                                                    let arr =
                                                      defaultListUser.filter(
                                                        (item) =>
                                                          item.jabatan === value
                                                      );
                                                    setListUser(arr);

                                                    const director =
                                                      form.getFieldValue(
                                                        'director'
                                                      ) || [];
                                                    director[index] = {
                                                      ...director[index],
                                                      name: '',
                                                      email: '',
                                                    };
                                                    form.setFieldsValue({
                                                      director,
                                                    });
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col span={8}>
                                            <div className="">
                                              <label className="field-required font-medium">
                                                Nama
                                              </label>
                                              <Form.Item
                                                name={[index, 'name']}
                                                rules={requiredField}
                                              >
                                                <Select
                                                  placeholder="Nama"
                                                  type="text"
                                                  className="mt-2"
                                                  style={{ width: '100%' }}
                                                  options={listUser}
                                                  disabled={disable}
                                                  onChange={(value, option) => {
                                                    const director =
                                                      form.getFieldValue(
                                                        'director'
                                                      ) || [];
                                                    director[index] = {
                                                      ...director[index],
                                                      email:
                                                        option.emailPegawai,
                                                    };
                                                    form.setFieldsValue({
                                                      director,
                                                    });
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                          <Col span={8}>
                                            <div className="">
                                              <label className="font-medium">
                                                Email
                                              </label>
                                              <Form.Item
                                                name={[index, 'email']}
                                              >
                                                <Input
                                                  placeholder=""
                                                  type="text"
                                                  className="form-input mt-2"
                                                  style={{ width: '100%' }}
                                                  disabled
                                                />
                                              </Form.Item>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                      {index > 0 && (
                                        <Col span={1}>
                                          <Button
                                            style={{
                                              width: '100%',
                                              height: '48',
                                            }}
                                            className="btn-default mt-8"
                                            onClick={() => remove(index)}
                                          >
                                            <DeleteOutlined />
                                          </Button>
                                        </Col>
                                      )}
                                      {index > directors.length - 2 && (
                                        <Col span={1}>
                                          <Button
                                            style={{
                                              width: '100%',
                                              height: '48px',
                                            }}
                                            className="btn-default mt-8"
                                            onClick={() => add()}
                                          >
                                            <PlusOutlined />
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  );
                                })}
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Informasi Narahubung
                      </p>
                      <div className="row mt-4">
                        <div className="row mt-2">
                          <div className="">
                            <label className="field-required font-medium">
                              Nama Narahubung
                            </label>
                            <Form.Item name={['contactPerson', 'name']}>
                              <Input
                                placeholder="Alamat Lengkap"
                                type="text"
                                className="form-input mt-2"
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Email
                              </label>
                              <Form.Item name={['contactPerson', 'email']}>
                                <Input
                                  placeholder="Jabatan"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                No Telepon
                              </label>
                              <Form.Item
                                name={['contactPerson', 'phoneNumber']}
                              >
                                <Input
                                  placeholder="Nama Direktur"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className="">
                              <label className="font-medium">Fax</label>
                              <Form.Item name={['contactPerson', 'fax']}>
                                <Input
                                  placeholder="Fax"
                                  type="text"
                                  className="form-input mt-2"
                                  style={{ width: '100%' }}
                                  disabled={disable}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <div className="row mt-2">
                          <div className="">
                            <label className="field-required font-medium">
                              Alamat Lengkap
                            </label>
                            <Form.Item name={['contactPerson', 'address']}>
                              <Input.TextArea
                                placeholder="Alamat Lengkap"
                                type="text"
                                className="form-input mt-2"
                                disabled={disable}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <hr className="break-line mb-4" />
                    </div>
                    <div className="px-2">
                      <p
                        className="text-xl font-medium"
                        style={{ color: '#01A24A' }}
                      >
                        Dana Pengajuan
                      </p>
                      <div className="row mt-4">
                        <Row gutter={24} className="">
                          <Col span={8}>
                            <div className="">
                              <label
                                className="field-required font-medium"
                                style={{ overflowX: 'visible' }}
                              >
                                Total Dana Program
                              </label>
                              <Row gutter={6} className="">
                                <Col span={9}>
                                  <Form.Item
                                    name={['submissionFunding', 'currency']}
                                    rules={requiredField}
                                  >
                                    <Select
                                      placeholder="USD"
                                      type="text"
                                      className="mt-2"
                                      style={{ width: '100%' }}
                                      options={[
                                        { value: 'USD', label: 'USD' },
                                        { value: 'IDR', label: 'IDR' },
                                        { value: 'EURO', label: 'EURO' },
                                      ]}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={15}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'funding_total',
                                    ]}
                                    rules={requiredField}
                                  >
                                    <InputNumber
                                      placeholder="10.000"
                                      className="form-input mt-2 w-full"
                                      style={{
                                        height: '47px',
                                        width: '100%',
                                        textAlign: 'right',
                                      }}
                                      formatter={currencyFormatter}
                                      parser={currencyParser}
                                      controls={false}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Jangka Waktu Hibah
                              </label>
                              <Row gutter={6} className="">
                                <Col span={9}>
                                  <Form.Item
                                    name={['submissionFunding', 'grant_period']}
                                    rules={requiredField}
                                  >
                                    <Input
                                      type="text"
                                      className="form-input mt-2"
                                      style={{ width: '100%' }}
                                      disabled={disable}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={15}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'grant_period_type',
                                    ]}
                                  >
                                    <Select
                                      placeholder="days/months/years"
                                      className="mt-2"
                                      prefix={<CalenderIcon />}
                                      type="text"
                                      style={{ width: '100%' }}
                                      disabled
                                      value={'months'}
                                      options={[
                                        // { value: 'days', label: 'Days' },
                                        { value: 'months', label: 'Months' },
                                        // { value: 'years', label: 'Years' },
                                      ]}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>

                          <Col span={8}>
                            <div className="">
                              <label className="field-required font-medium">
                                Periode Pelaksanaan Program
                              </label>
                              <Row gutter={6}>
                                <Col span={12}>
                                  <Form.Item
                                    name={['submissionFunding', 'date_period']}
                                    rules={requiredField}
                                  >
                                    <DatePicker
                                      suffixIcon={<CalenderIcon />}
                                      // format={"dd/mm/yyyy"}
                                      placeholder="dd/mm/yyyy"
                                      className="form-input mt-2"
                                      prefix={<CalenderIcon />}
                                      style={{ width: '100%' }}
                                      disabled={disable}
                                    >
                                      <Icon
                                        component={CalenderIcon}
                                        className="mr-2"
                                        style={{ fontSize: 15 }}
                                      />
                                    </DatePicker>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    name={[
                                      'submissionFunding',
                                      'end_date_period',
                                    ]}
                                    rules={requiredField}
                                  >
                                    <DatePicker
                                      suffixIcon={<CalenderIcon />}
                                      // format={"dd/mm/yyyy"}
                                      placeholder="dd/mm/yyyy"
                                      className="form-input mt-2"
                                      prefix={<CalenderIcon />}
                                      style={{ width: '100%' }}
                                      disabledDate={(d) =>
                                        !d || d.isAfter(maximumEndDate())
                                      }
                                      disabled={disable}
                                    >
                                      <Icon
                                        component={CalenderIcon}
                                        className="mr-2"
                                        style={{ fontSize: 15 }}
                                      />
                                    </DatePicker>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Program Prioritas"
                  key="1"
                  extra={buttonLogPanel('priorityPrograms')}
                >
                  <div className="">
                    <PriorityProgram
                      Form={Form}
                      form={form}
                      disable={disable}
                      expandIcon={expandIcon}
                      handlePanelChange={handlePanelChange}
                      requiredField={requiredField}
                      name={'priorityPrograms'}
                    />
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Organization Background (IEF)"
                  key="1"
                  extra={buttonLogPanel('organizationBackground')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="organizationBackground"
                        data={dataProposal}
                        rtfIndex={1}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Needs"
                  key="1"
                  extra={buttonLogPanel('needs')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="needs"
                        data={dataProposal}
                        rtfIndex={2}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Organizational Landscape"
                  key="1"
                  extra={buttonLogPanel('organizationalLandscape')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        form={form}
                        disable={disable}
                        name="organizationalLandscape"
                        data={dataProposal}
                        rtfIndex={3}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4 bg-white"
                  header="Project Prioritas"
                  key="1"
                  extra={buttonLogPanel('projectPriorityPrograms')}
                  rtfIndex={4}
                >
                  <div className="">
                    <ProjectPriorityProgram
                      Form={Form}
                      form={form}
                      disable={disable}
                      expandIcon={expandIcon}
                      handlePanelChange={handlePanelChange}
                      requiredField={requiredField}
                      name={'projectPriorityPrograms'}
                    />
                  </div>
                </Panel>
                <div className="flex justify-center">
                  <hr style={{ width: '97%' }} className="break-line mb-4" />
                </div>
                <div className="px-5">
                  <div className="">
                    <label className="field-required font-medium">
                      Investment Criteria
                    </label>
                    <Form.Item name="investmentCriteria">
                      <Select
                        className="mt-2"
                        type="text"
                        mode="multiple"
                        options={listIC}
                        disabled={disable}
                      />
                    </Form.Item>
                  </div>
                  <div className="">
                    <label className="field-required font-medium">
                      Financial Instrument
                    </label>
                    <Form.Item name="financialInstrument">
                      <Select
                        className="mt-2"
                        type="text"
                        mode="multiple"
                        options={listFI}
                        disabled={disable}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Penyaluran"
                  key="1"
                  extra={buttonLogPanel('distribution')}
                >
                  <div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Mekanisme Alokasi</label>
                        <Form.Item
                          name={['distribution', 'allocationMechanism']}
                        >
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={listMA}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">
                          Mekanisme Penyaluran
                        </label>
                        <Form.Item
                          name={['distribution', 'distributionMechanism']}
                        >
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={listMP}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Lokasi (Provinsi)</label>
                        <Form.Item name={['distribution', 'location']}>
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={[
                              { label: 'All Province', value: 'All Province' },
                              ...listProvinsi,
                            ]}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row px-4 mt-4">
                      <div className="">
                        <label className="font-medium">Penerima Manfaat</label>
                        <Form.Item name={['distribution', 'beneficiary']}>
                          <Select
                            className="mt-2"
                            type="text"
                            mode="multiple"
                            options={listPM}
                            disabled={disable}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
              <hr className="mb-6" />

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Risk Management"
                  key="1"
                  extra={buttonLogPanel('principleObstacle')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="principleObstacle"
                        form={form}
                        data={dataProposal}
                        rtfIndex={5}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Communication Strategy"
                  key="1"
                  extra={buttonLogPanel('communicatingResult')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="communicatingResult"
                        form={form}
                        data={dataProposal}
                        rtfIndex={6}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Project Management Structure"
                  key="1"
                  extra={buttonLogPanel('projectStructure')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="projectStructure"
                        form={form}
                        data={dataProposal}
                        rtfIndex={7}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Safeguards"
                  key="1"
                  extra={buttonLogPanel('safeguards')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="safeguards"
                        form={form}
                        data={dataProposal}
                        rtfIndex={8}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>

              <Collapse
                expandIcon={expandIcon}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                onChange={handlePanelChange}
                className="mt-4 mb-8"
              >
                <Panel
                  className="custom-panel px-5 pb-4"
                  header="Others"
                  key="1"
                  extra={buttonLogPanel('others')}
                >
                  <div className="">
                    <div className="mt-2">
                      <CustomQuill
                        Form={Form}
                        disable={disable}
                        name="others"
                        form={form}
                        data={dataProposal}
                        rtfIndex={9}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>

            <Row>
              <Col span={24}>
                <div
                  className="mt-6 px-4"
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #EAECF0',
                    background: '#FFFF',
                    position: 'fixed',
                    bottom: 0,
                    width: 'calc(100vw - 400px)',
                  }}
                >
                  {role === 'staff-ppd' &&
                  role !== 'staff-kl' &&
                  role !== 'donor' &&
                  role !== 'kadiv-ppd' &&
                  role !== 'direktur-kl' &&
                  role !== 'direktorat-lain' ? (
                    <div className="flex justify-between items-center">
                      <Space>
                        <NavLink to={'/proposal-submission'}>
                          <Button
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                              width: '240px',
                              height: '50px',
                            }}
                            icon={<ArrowLeftOutlined />}
                            className="flex justify-center items-center font-medium"
                          >
                            <span className="ml-2 text-center">Kembali</span>
                          </Button>
                        </NavLink>
                      </Space>
                      <div className="row">
                        <Form.Item>
                          <Space>
                            {params.addendum && (
                              <Button
                                style={{
                                  borderRadius: '8px',
                                  border: '1px solid #D0D5DD',
                                  width: '160px',
                                  height: '52px',
                                }}
                                icon={<SaveOutlined />}
                                className="flex justify-center items-center font-medium mt-4"
                                onClick={() => {
                                  form.setFieldsValue({ draft: true });
                                  onFinish(form.getFieldsValue(true));
                                }}
                                disabled={disable}
                              >
                                <span className="ml-2 text-center">
                                  Simpan & Draft
                                </span>
                              </Button>
                            )}
                            <Button
                              // htmlType="submit"
                              onClick={() => {
                                form.setFieldsValue({ draft: false });
                                onFinish(form.getFieldsValue(true));
                              }}
                              className="flex justify-center items-center font-medium mt-4 ml-4 btn-approve"
                              disabled={
                                (dataProposal?.status === 'approved' &&
                                  !params.addendum) ||
                                disable
                              }
                            >
                              <span className="ml-2 text-center">Submit</span>
                            </Button>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                  ) : role === 'kadiv-ppd' ||
                    role === 'direktur-kl' ||
                    role === 'direktur-utama' ||
                    role === 'director-presidentDirector' ||
                    role === 'director' ||
                    role === 'donor' ||
                    role === 'staff-kl' ||
                    role === 'direktorat-lain' ? (
                    <div className="flex justify-between items-center">
                      <Space>
                        <NavLink to={'/proposal-submission'}>
                          <Button
                            style={{
                              borderRadius: '8px',
                              border: '1px solid #EAECF0',
                              width: '240px',
                              height: '50px',
                            }}
                            icon={<ArrowLeftOutlined />}
                            className="flex justify-center items-center font-medium"
                          >
                            <span className="ml-2 text-center">Kembali</span>
                          </Button>
                        </NavLink>
                      </Space>
                      <div className="row">
                        <Form.Item>
                          <Space>
                            <Button
                              icon={<CloseCircleOutlined />}
                              className={`flex justify-center items-center font-medium mt-6 ml-4 btn-danger`}
                              onClick={onRejectProposal}
                              disabled={disable}
                            >
                              <span className="ml-2 text-center">
                                Tolak Proposal
                              </span>
                            </Button>
                            <Button
                              icon={<CheckCircleOutlined />}
                              className={`flex justify-center items-center font-medium mt-6 ml-4 btn-approve`}
                              onClick={onSubmitProposal}
                              disabled={disable}
                            >
                              <span className="ml-2 text-center">
                                Setujui Proposal
                              </span>
                            </Button>
                            <Button
                              style={{
                                borderRadius: '8px',
                                border: '1px solid #EAECF0',
                                width: '180px',
                                height: '50px',
                              }}
                              onClick={() => {
                                form.setFieldsValue({ draft: false });
                                onFinish(form.getFieldsValue(true));
                              }}
                              icon={<Icon component={SaveIcon} />}
                              className="flex justify-center items-center font-medium mt-6 ml-4 btn-default"
                              disabled={disable}
                            >
                              <span className="ml-2 text-center">
                                Simpan Perubahan
                              </span>
                            </Button>
                          </Space>
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <LogActivityModal
            idProposal={params.id}
            section={logSection}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />

          <HistoryReviewModal
            idProposal={params.id}
            section={logSection}
            openModal={openModalHistory}
            setOpenModal={setOpenModalHistory}
          />

          <ProposalModal
            idProposal={params.id}
            openModal={modalProposal}
            setOpenModal={setModalProposal}
            proposal={dataProposal}
          />

          <Modal
            title="Tolak Proposal"
            open={isReject}
            onCancel={closeModalRejectProposal}
            width={700}
            footer={[
              <div className="flex justify-center mt-6">
                <Button
                  onClick={closeModalRejectProposal}
                  size="large"
                  className="font-medium"
                  style={{ width: '290px' }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="font-medium"
                  style={{ width: '290px' }}
                  onClick={onProposalRejected}
                >
                  Confirm
                </Button>
              </div>,
            ]}
          >
            <div className="mt-4">
              <label className="field-required font-medium mt-4">Alasan</label>
              <Form.Item name="reason">
                <Input.TextArea
                  placeholder="Alasan"
                  type="text"
                  className="form-input mt-4 mb-4"
                />
              </Form.Item>
            </div>
          </Modal>
        </Form>
      </Spin>
    </>
  );
};

export default EditProposalSubmission;
