import React, { useEffect, useState } from 'react';
import {
  Input,
  Form,
  Button,
  Space,
  notification,
  Spin,
  Row,
  Col,
  Switch,
  Select,
  DatePicker,
  InputNumber,
} from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  WarningOutlined,
  SaveOutlined,
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import './index.scss';
import { useNavigate, NavLink, useParams, redirect } from 'react-router-dom';
import Icon, { RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'react-quill/dist/quill.snow.css';

import { BASE_URL, MASTER_DATA_URL } from 'services/config';
import { currencyFormatter, currencyParser } from 'utils';
import { PriorityProgram } from 'components/organisms/PriorityProgram';
import CustomQuill from 'components/organisms/CustomQuill';

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CalenderIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.166 2.50016H17.4993C17.7204 2.50016 17.9323 2.58796 18.0886 2.74424C18.2449 2.90052 18.3327 3.11248 18.3327 3.3335V16.6668C18.3327 16.8878 18.2449 17.0998 18.0886 17.2561C17.9323 17.4124 17.7204 17.5002 17.4993 17.5002H2.49935C2.27834 17.5002 2.06637 17.4124 1.91009 17.2561C1.75381 17.0998 1.66602 16.8878 1.66602 16.6668V3.3335C1.66602 3.11248 1.75381 2.90052 1.91009 2.74424C2.06637 2.58796 2.27834 2.50016 2.49935 2.50016H5.83268V0.833496H7.49935V2.50016H12.4993V0.833496H14.166V2.50016ZM12.4993 4.16683H7.49935V5.8335H5.83268V4.16683H3.33268V7.50016H16.666V4.16683H14.166V5.8335H12.4993V4.16683ZM16.666 9.16683H3.33268V15.8335H16.666V9.16683Z"
      fill="#667085"
    />
  </svg>
);

const CreateProposalSubmissionStepOne = () => {
  const params = useParams();
  let navigate = useNavigate();

  const requiredField = [{ required: true, message: 'Wajib Diisi' }];
  // const [value, setValue] = useState('');
  const [form] = Form.useForm();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [loading, setLoading] = useState(false);
  const [colOrgBackground, setColOrgBackground] = useState(true);
  const [colNeeds, setColNeeds] = useState(true);
  const [colLandscape, setColLandscape] = useState(true);
  const [expandedPanels, setExpandedPanels] = useState([]);

  const [listNegara, setListNegara] = useState([]);
  const [listProvinsi, setListProvinsi] = useState([]);
  const [listKabupaten, setListKabupaten] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [listKelurahan, setListKelurahan] = useState([]);
  const [listKodePos, setListKodePos] = useState([]);

  const [listJabatan, setListJabatan] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [defaultListUser, setDefaultListUser] = useState([]);

  const loadListNegara = () => {
    axios
      .get(`${MASTER_DATA_URL}/Negara/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaNegara,
              label: item.namaNegara,
            });
          });

          setListNegara(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListProvinsi = () => {
    axios
      .get(`${MASTER_DATA_URL}/Provinsi/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaProvinsi,
              label: item.namaProvinsi,
            });
          });

          setListProvinsi(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKabupatenKota = () => {
    axios
      .get(`${MASTER_DATA_URL}/KabupatenKota/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKabupatenKota,
              label: item.namaKabupatenKota,
            });
          });

          setListKabupaten(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKecamatan = () => {
    axios
      .get(`${MASTER_DATA_URL}/Kecamatan/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKecamatan,
              label: item.namaKecamatan,
            });
          });

          setListKecamatan(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKelurahan = () => {
    axios
      .get(`${MASTER_DATA_URL}/Kelurahan/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.namaKelurahan,
              label: item.namaKelurahan,
            });
          });

          setListKelurahan(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListKodePos = () => {
    axios
      .get(`${MASTER_DATA_URL}/KodePos/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.kodePos,
              label: item.kodePos,
            });
          });

          setListKodePos(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadListUser = () => {
    axios
      .get(`https://bpldh-api-v2.groot.id/api/user-management/v1/Employee/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];
          let arr2 = [];

          res.data?.data?.forEach((item) => {
            if (
              !arr2.some((i) => i.value === item.jabatan) &&
              item.jabatan &&
              item.jabatan.includes('Direktur Utama')
            ) {
              arr2.push({
                key: item.id,
                value: item.jabatan,
                label: item.jabatan,
              });
            }
            arr.push({
              ...item,
              key: item.id,
              value: item.name,
              label: item.name,
            });
          });

          setListJabatan(arr2);
          setDefaultListUser(arr);
          setListUser(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      loadDetailDataProposal();
    }

    loadListNegara();
    loadListProvinsi();
    loadListKabupatenKota();
    loadListKecamatan();
    loadListKelurahan();
    loadListKodePos();
    loadListUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataProposal = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/proposal/${params.id}`)
      .then((res) => {
        let proposal = res.data.result;
        setLoading(false);
        if (
          !proposal?.generalInformation.objectives ||
          proposal?.generalInformation.objectives.length < 1
        ) {
          proposal.generalInformation.objectives = [''];
        }
        form.setFieldsValue({
          generalInformation: proposal.generalInformation,
        });
        form.setFieldsValue({ director: proposal.director });
        form.setFieldsValue({ contactPerson: proposal.contactPerson });

        proposal.submissionFunding.date_period = dayjs(
          proposal.submissionFunding.date_period
        );
        proposal.submissionFunding.end_date_period = dayjs(
          proposal.submissionFunding.end_date_period
        );
        form.setFieldsValue({ submissionFunding: proposal.submissionFunding });
        form.setFieldsValue({ distribution: proposal.distribution });

        proposal.priorityPrograms?.forEach((item, idx) => {
          if (proposal.priorityPrograms[idx].impact?.indicators?.length < 1) {
            proposal.priorityPrograms[idx].impact.indicators = [{}];
          }

          proposal.priorityPrograms[idx].impact?.outcomes?.forEach(
            (outcome, outcomeIndex) => {
              if (
                proposal.priorityPrograms[idx].impact?.outcomes[outcomeIndex]
                  .indicators?.length < 1 ||
                !proposal.priorityPrograms[idx].impact?.outcomes[outcomeIndex]
                  .indicators
              ) {
                proposal.priorityPrograms[idx].impact.outcomes[
                  outcomeIndex
                ].indicators = [{}];
              }
              outcome?.outputs?.forEach((output) => {
                output?.subOutputs?.forEach((subOutput) => {
                  subOutput?.indicators?.forEach((indicator) => {
                    indicator?.parameters?.forEach((parameter) => {
                      let arr = [];

                      parameter?.indicativeActivities?.forEach((activity) => {
                        arr.push(activity.indicativeActivityName);
                      });

                      parameter._indicativeActivities = arr;
                    });
                  });
                });
              });
            }
          );
        });
        form.setFieldsValue({ priorityPrograms: proposal.priorityPrograms });

        form.setFieldsValue({
          organizationBackground: proposal.organizationBackground,
        });
        form.setFieldsValue({ needs: proposal.needs });
        form.setFieldsValue({
          organizationalLandscape: proposal.organizationalLandscape,
        });

        form.setFieldsValue({ status: proposal.status });
        form.setFieldsValue({ review: proposal.review });

        console.log(proposal);
      })
      .catch((err) => {
        let errorMessage =
          err?.response?.data.message || 'Internal Server Error';
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage,
        });
        setLoading(false);
      });
  };

  const onFinish = (values, nav_btn) => {
    setLoading(true);
    const payload = values;

    let submissionPath = params?.id
      ? `${BASE_URL}/proposal/${params?.id}/step/1`
      : `${BASE_URL}/proposal`;
    let submissionMethod = params?.id ? 'put' : 'post';

    axios({ method: submissionMethod, url: submissionPath, data: payload })
      .then((res) => {
        setLoading(false);
        if (res) {
          if (nav_btn === 'list') {
            return navigate(`/proposal-submission`);
          } else {
            return navigate(
              `/proposal-submission/create-proposal/step/2/${res?.data?.result.id}`
            );
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
        let errorMessage;
        let errorMessages = [];

        if (
          err?.response?.data?.errors === undefined ||
          err?.response?.data?.errors.length === 0
        ) {
          errorMessage = err?.response?.data?.message;
        } else {
          <ul style={{ listStyleType: 'disc' }}>
            {err?.response?.data?.errors.forEach((error) => {
              errorMessages.push(<li>&#x2022; {error.message}</li>);
            })}
          </ul>;
        }
        notification.error({
          message: 'Kesalahan',
          duration: 5,
          description: errorMessage ? errorMessage : errorMessages,
        });
        setLoading(false);
      });
  };

  const handlePanelChange = (panelKey) => {
    const expanded = [...expandedPanels];
    const index = expanded.indexOf(panelKey);

    if (index > -1) {
      expanded.splice(index, 1);
    } else {
      expanded.push(panelKey);
    }

    setExpandedPanels(expanded);
  };

  const expandIcon = ({ isActive }) => {
    if (isActive) {
      return <DownOutlined style={{ fontSize: 14 }} />;
    }
    return <UpOutlined style={{ fontSize: 14 }} />;
  };

  const maximumEndDate = () => {
    let sf = form.getFieldValue('submissionFunding');

    if (sf.grant_period && sf.grant_period_type) {
      if (sf.grant_period_type === 'days') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'day');
        return dayjs(max).format('YYYY-MM-DD');
      } else if (sf.grant_period_type === 'months') {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'month');
        return dayjs(max).format('YYYY-MM-DD');
      } else {
        let max = dayjs(sf.date_period).add(sf.grant_period, 'year');
        return dayjs(max).format('YYYY-MM-DD');
      }
    } else {
      return null;
    }
  };

  const onFinishFailed = (errorInfo) => {
    const namePath = errorInfo.errorFields[0].name;
    // form.getFieldInstance(namePath)?.input.scrollIntoView({ behavior: 'smooth', block: 'center' });

    form.getFieldInstance(namePath)?.focus();
  };

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <Form
            form={form}
            name="form-create"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError={true}
            initialValues={{
              generalInformation: {
                objectives: [''],
                organizationName: 'Indonesia Environmental Fund (IEF)',
                country: 'Indonesia',
                province: 'DKI Jakarta',
                city: 'Jakarta Pusat',
                district: 'Gambir',
                subdistrict: 'Gambir',
                zipcode: '10110',
                address:
                  'JB Tower 29 - 30th Floor, Kebon Sirih Street, No. 48-50, RT 11/RW 2, Gambir, DKI Jakarta, 10110',
                phoneNumber: '0213505226',
                email: 'bpdlh@kemenkeu.go.id',
                website: 'https://bpdlh.kemenkeu.go.id/',
              },
              contactPerson: {
                name: 'Endah Tri Kurniawaty',
                email: 'endah_nia@yahoo.com',
                phoneNumber: '0816859685',
                address:
                  'JB Tower 29 - 30th Floor, Kebon Sirih Street, No. 48-50, RT 11/RW 2, Gambir, DKI Jakarta, 10110',
              },
              director: [{}],
              priorityPrograms: [
                {
                  impact: {
                    indicators: [{}],
                    outcomes: [{
                      indicators: [{}],
                      outputs: [{ subOutputs: [{}] }],
                    }],
                  }
                },
              ],
              submissionFunding: { grant_period_type: 'months' },
            }}
          >
            <div className="flex items-center py-5 flex-wrap">
              <Space>
                <Icon component={HomeLine} className="mr-2" />
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <p className="font-medium mt-2 mr-3">
                  Daftar Proposal Submission
                </p>
              </div>
              <Space>
                <RightOutlined className="mr-3 text-xs right-cursor" />
              </Space>
              <div>
                <Button className="btn-navigation mt-2">
                  <span className="font-medium text-center">
                    Ajukan Proposal
                  </span>
                </Button>
              </div>
            </div>
            <div className="mb-4" style={{ marginBottom: '40px' }}>
              <div div className="flex" style={{ marginLeft: '150px' }}>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #00BD52',
                        background: '#EEFFF4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#00BD52',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#00BD52',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi General
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Informasi Project
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '28px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                    <div
                      className="pipe"
                      style={{
                        background: '#EAECF0',
                        width: '100%',
                        height: '2px',
                      }}
                    ></div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Direktorat & Donor
                    </p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div
                    className="flex"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      className="circle flex"
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                        border: '1px solid #EAECF0',
                        background: '#FFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        className="inner-circle"
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          background: '#EAECF0',
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="tittle">
                    <p
                      style={{
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '-45px',
                        marginTop: '5px',
                      }}
                    >
                      Dokumen Pendukung
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="warning-box">
              <WarningOutlined style={{ fontSize: 20, marginRight: 10 }} />
              <p>
                Silahkan isi semua data di bawah ini untuk bisa melanjutkan
                pengisian ke tahap selanjutnya
              </p>
            </div>
            <div
              className="list overflow-hidden mb-6"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Informasi General</p>
                </div>
                <hr />
                <div>
                  <div className="row px-5 mt-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Judul Proposal
                      </label>
                      <Form.Item
                        name={['generalInformation', 'title']}
                        rules={[
                          ...requiredField,
                          { min: 5, max: 255, message: "Minimal 5 - 255 karakter" }
                        ]}
                      >
                        <Input
                          placeholder="Judul Proposal"
                          type="text"
                          className="form-input mt-2"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-2 mb-4">
                    <div className="">
                      <label className="field-required font-medium">
                        Objektif Proposal
                      </label>
                      <Form.Item
                        shouldUpdate
                      >
                        <Form.List name={['generalInformation', 'objectives']}>
                          {(objectiveProposal, { add, remove }) => (
                            <>
                              {objectiveProposal.map((objective, index) => {
                                return (
                                  <Row gutter={12}>
                                    <Col span={22}>
                                      <Form.Item
                                        name={[index]}
                                        rules={[
                                          ...requiredField,
                                          { min: 5, max: 255, message: 'Minimal 5 - 255 karakter' },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Objektif Proposal"
                                          type="text"
                                          className="form-input mt-2"
                                        />
                                      </Form.Item>
                                    </Col>
                                    {index > 0 && (
                                      <Col span={1}>
                                        <Button
                                          style={{
                                            width: '100%',
                                            height: '48',
                                          }}
                                          className="btn-default mt-2"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Col>
                                    )}
                                    {index > objectiveProposal.length - 2 && (
                                      <Col span={1}>
                                        <Button
                                          style={{
                                            width: '100%',
                                            height: '48px',
                                          }}
                                          className="btn-default mt-2"
                                          onClick={() => add()}
                                        >
                                          <PlusOutlined />
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                );
                              })}
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-2 mb-8">
                    <div className="">
                      <label className="field-required font-medium">
                        Nama Organisasi
                      </label>
                      <Form.Item
                        name={['generalInformation', 'organizationName']}
                        rules={[
                          ...requiredField,
                          { min: 5, max: 255, message: 'Minimal 5 - 255 karakter' },
                        ]}
                      >
                        <Input
                          placeholder="Nama Organisasi"
                          type="text"
                          className="form-input mt-2"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Negara
                          </label>
                          <Form.Item
                            name={['generalInformation', 'country']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Negara"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listNegara}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Provinsi
                          </label>
                          <Form.Item
                            name={['generalInformation', 'province']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Provinsi"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listProvinsi}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Kota/Kabupaten
                          </label>
                          <Form.Item
                            name={['generalInformation', 'city']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Kota/Kabupaten"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listKabupaten}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="row px-5 mt-2">
                    <Row gutter={24} className="">
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Kecamatan
                          </label>
                          <Form.Item
                            name={['generalInformation', 'district']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Kecamatan"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listKecamatan}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Kelurahan
                          </label>
                          <Form.Item
                            name={['generalInformation', 'subdistrict']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Kelurahan"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listKelurahan}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Kode Pos
                          </label>
                          <Form.Item
                            name={['generalInformation', 'zipcode']}
                            rules={requiredField}
                          >
                            <Select
                              placeholder="Kode Pos"
                              type="text"
                              className="mt-2"
                              style={{ width: '100%' }}
                              options={listKodePos}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="row px-5 mt-2">
                    <div className="">
                      <label className="field-required font-medium">
                        Alamat Lengkap
                      </label>
                      <Form.Item
                        name={['generalInformation', 'address']}
                        rules={requiredField}
                      >
                        <Input.TextArea
                          placeholder="Alamat Lengkap"
                          type="text"
                          className="form-input mt-2"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row px-5 mt-4">
                    <Row gutter={24} className="">
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            No Telepon
                          </label>
                          <Form.Item
                            name={['generalInformation', 'phoneNumber']}
                            rules={[
                              ...requiredField,
                              { min: 8, max: 16, message: 'Minimal 8 - 16 karakter' },
                            ]}
                          >
                            <Input
                              placeholder="No Telepon"
                              type="number"
                              className="form-input mt-2"
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Email
                          </label>
                          <Form.Item
                            name={['generalInformation', 'email']}
                            rules={[
                              ...requiredField,
                              { type: 'email', message: 'Format tidak sesuai' },
                            ]}
                          >
                            <Input
                              placeholder="Email"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="">
                          <label className="font-medium">Fax</label>
                          <Form.Item name={['generalInformation', 'fax']}>
                            <Input
                              placeholder="Fax"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="row px-5">
                    <Row gutter={24} className="">
                      <Col span={8}>
                        <div className="">
                          <label className="field-required font-medium">
                            Website
                          </label>
                          <Form.Item
                            name={['generalInformation', 'website']}
                            rules={requiredField}
                          >
                            <Input
                              placeholder="Website"
                              type="text"
                              className="form-input mt-2"
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="flex justify-center">
                    <hr className="break-line mb-4" />
                  </div>
                  <div className="px-5">
                    <p
                      className="text-2xl font-medium"
                      style={{ color: '#01A24A' }}
                    >
                      Informasi Direktur
                    </p>
                    <div className="row mt-4">
                      <Form.Item
                        shouldUpdate
                        // rules={requiredField}
                      >
                        <Form.List name={['director']}>
                          {(directors, { add, remove }) => (
                            <>
                              {directors.map((director, index) => {
                                return (
                                  <Row gutter={12}>
                                    <Col span={22}>
                                      <Row gutter={24} className="">
                                        <Col span={8}>
                                          <div className="">
                                            <label className="field-required font-medium">
                                              Jabatan
                                            </label>
                                            <Form.Item
                                              name={[index, 'position']}
                                              rules={requiredField}
                                            >
                                              <Select
                                                placeholder="Jabatan"
                                                type="text"
                                                className="mt-2"
                                                style={{ width: '100%' }}
                                                options={listJabatan}
                                                onChange={(value) => {
                                                  let arr =
                                                    defaultListUser.filter(
                                                      (item) =>
                                                        item.jabatan === value
                                                    );
                                                  setListUser(arr);

                                                  const director =
                                                    form.getFieldValue(
                                                      'director'
                                                    ) || [];
                                                  director[index] = {
                                                    ...director[index],
                                                    name: '',
                                                    email: '',
                                                  };
                                                  form.setFieldsValue({
                                                    director,
                                                  });
                                                }}
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col span={8}>
                                          <div className="">
                                            <label className="field-required font-medium">
                                              Nama
                                            </label>
                                            <Form.Item
                                              name={[index, 'name']}
                                              rules={requiredField}
                                            >
                                              <Select
                                                placeholder="Nama"
                                                type="text"
                                                className="mt-2"
                                                style={{ width: '100%' }}
                                                options={listUser}
                                                onChange={(value, option) => {
                                                  const director =
                                                    form.getFieldValue(
                                                      'director'
                                                    ) || [];
                                                  director[index] = {
                                                    ...director[index],
                                                    email: option.emailPegawai,
                                                  };
                                                  form.setFieldsValue({
                                                    director,
                                                  });
                                                }}
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                        <Col span={8}>
                                          <div className="">
                                            <label className="font-medium">
                                              Email
                                            </label>
                                            <Form.Item
                                              name={[index, 'email']}
                                              rules={[
                                                { type: 'email', message: 'Format tidak sesuai' },
                                              ]}
                                            >
                                              <Input
                                                placeholder=""
                                                type="text"
                                                className="form-input mt-2"
                                                style={{ width: '100%' }}
                                                disabled
                                              />
                                            </Form.Item>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    {index > 0 && (
                                      <Col span={1}>
                                        <Button
                                          style={{
                                            width: '100%',
                                            height: '48',
                                          }}
                                          className="btn-default mt-8"
                                          onClick={() => remove(index)}
                                        >
                                          <DeleteOutlined />
                                        </Button>
                                      </Col>
                                    )}
                                    {index > directors.length - 2 && (
                                      <Col span={1}>
                                        <Button
                                          style={{
                                            width: '100%',
                                            height: '48px',
                                          }}
                                          className="btn-default mt-8"
                                          onClick={() => add()}
                                        >
                                          <PlusOutlined />
                                        </Button>
                                      </Col>
                                    )}
                                  </Row>
                                );
                              })}
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <hr className="break-line mb-4" />
                  </div>
                  <div className="px-5">
                    <p
                      className="text-2xl font-medium"
                      style={{ color: '#01A24A' }}
                    >
                      Informasi Narahubung
                    </p>
                    <div className="row mt-4">
                      <div className="row mt-2">
                        <div className="">
                          <label className="field-required font-medium">
                            Nama Narahubung
                          </label>
                          <Form.Item
                            name={['contactPerson', 'name']}
                            rules={[
                              ...requiredField,
                              { min: 5, max: 255, message: 'Minimal 5 - 255 karakter' },
                            ]}
                          >
                            <Input
                              placeholder="Nama Narahubung"
                              type="text"
                              className="form-input mt-2"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Email
                            </label>
                            <Form.Item
                              name={['contactPerson', 'email']}
                              rules={[
                                ...requiredField,
                                { type: 'email', message: 'Format tidak sesuai' }
                              ]}
                            >
                              <Input
                                placeholder="Email"
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              No Telepon
                            </label>
                            <Form.Item
                              name={['contactPerson', 'phoneNumber']}
                              rules={[
                                ...requiredField,
                                { min: 8, max: 16, message: 'Minimal 8 - 16 karakter' },
                              ]}
                            >
                              <Input
                                placeholder="No Telepon"
                                type="number"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div className="">
                            <label className="font-medium">Fax</label>
                            <Form.Item name={['contactPerson', 'fax']}>
                              <Input
                                placeholder=""
                                type="text"
                                className="form-input mt-2"
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <div className="row mt-2">
                        <div className="">
                          <label className="field-required font-medium">
                            Alamat Lengkap
                          </label>
                          <Form.Item
                            name={['contactPerson', 'address']}
                            rules={requiredField}
                          >
                            <Input.TextArea
                              placeholder="Alamat Lengkap"
                              type="text"
                              className="form-input mt-2"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <hr className="break-line mb-4" />
                  </div>
                  <div className="px-5">
                    <p
                      className="text-xl font-medium"
                      style={{ color: '#01A24A' }}
                    >
                      Dana Pengajuan
                    </p>
                    <div className="row mt-4">
                      <Row gutter={24} className="">
                        <Col span={8}>
                          <div className="">
                            <label
                              className="field-required font-medium"
                              style={{ overflowX: 'visible' }}
                            >
                              Total Dana Program
                            </label>
                            <Row gutter={6} className="">
                              <Col span={9}>
                                <Form.Item
                                  name={['submissionFunding', 'currency']}
                                  rules={requiredField}
                                >
                                  <Select
                                    placeholder="USD"
                                    type="text"
                                    className="mt-2"
                                    style={{ width: '100%' }}
                                    options={[
                                      { value: 'USD', label: 'USD' },
                                      { value: 'IDR', label: 'IDR' },
                                      { value: 'EURO', label: 'EURO' },
                                      { value: 'GBP', label: 'GBP' },

                                      { value: 'JPY', label: 'JPY' },
                                      { value: 'KRW', label: 'KRW' },
                                      { value: 'CNY', label: 'CNY' },
                                      { value: 'MYR', label: 'MYR' },
                                      { value: 'THB', label: 'THB' },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={15}>
                                <Form.Item
                                  name={['submissionFunding', 'funding_total']}
                                  rules={requiredField}
                                >
                                  <InputNumber
                                    placeholder="10.000"
                                    className="form-input mt-2 w-full"
                                    style={{
                                      height: '47px',
                                      width: '100%',
                                      textAlign: 'right',
                                    }}
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    controls={false}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Jangka Waktu Hibah
                            </label>
                            <Row gutter={6} className="">
                              <Col span={9}>
                                <Form.Item
                                  name={['submissionFunding', 'grant_period']}
                                  rules={requiredField}
                                >
                                  <Input
                                    type="text"
                                    className="form-input mt-2"
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={15}>
                                <Form.Item
                                  name={[
                                    'submissionFunding',
                                    'grant_period_type',
                                  ]}
                                >
                                  <Select
                                    // placeholder="days/months/years"
                                    className="mt-2"
                                    prefix={<CalenderIcon />}
                                    type="text"
                                    style={{ width: '100%' }}
                                    disabled
                                    value={'months'}
                                    defaultValue="months"
                                    options={[
                                      // { value: 'days', label: 'Days' },
                                      { value: 'months', label: 'Months' },
                                      // { value: 'years', label: 'Years' },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col span={8}>
                          <div className="">
                            <label className="field-required font-medium">
                              Periode Pelaksanaan Program
                            </label>
                            <Row gutter={6}>
                              <Col span={12}>
                                <Form.Item
                                  name={['submissionFunding', 'date_period']}
                                  rules={requiredField}
                                >
                                  <DatePicker
                                    suffixIcon={<CalenderIcon />}
                                    // format={"dd/mm/yyyy"}
                                    placeholder="dd/mm/yyyy"
                                    className="form-input mt-2"
                                    prefix={<CalenderIcon />}
                                    style={{ width: '100%' }}
                                  >
                                    <Icon
                                      component={CalenderIcon}
                                      className="mr-2"
                                      style={{ fontSize: 15 }}
                                    />
                                  </DatePicker>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[
                                    'submissionFunding',
                                    'end_date_period',
                                  ]}
                                  rules={requiredField}
                                >
                                  <DatePicker
                                    suffixIcon={<CalenderIcon />}
                                    // format={"dd/mm/yyyy"}
                                    placeholder="dd/mm/yyyy"
                                    className="form-input mt-2"
                                    prefix={<CalenderIcon />}
                                    style={{ width: '100%' }}
                                    disabledDate={(d) =>
                                      !d || d.isAfter(maximumEndDate())
                                    }
                                  >
                                    <Icon
                                      component={CalenderIcon}
                                      className="mr-2"
                                      style={{ fontSize: 15 }}
                                    />
                                  </DatePicker>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="w-100 overflow-hidden mb-6"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
              }}
            >
              <div className="">
                <div
                  className=""
                  style={{ background: '#00BD52', height: '10px' }}
                ></div>
                <div className="px-5 py-5">
                  <p className="text-2xl font-medium">Program Prioritas</p>
                </div>
                <hr />
                <PriorityProgram
                  Form={Form}
                  form={form}
                  expandIcon={expandIcon}
                  handlePanelChange={handlePanelChange}
                  requiredField={requiredField}
                  name={'priorityPrograms'}
                />
              </div>
            </div>
            <div
              className="list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">
                  Organization Background (IEF)
                </p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    className="ml-2"
                    onChange={setColOrgBackground}
                  />
                </div>
              </div>
              <hr />
              {colOrgBackground ? (
                <div className="mt-2 px-4">
                  <CustomQuill
                    Form={Form}
                    form={form}
                    name="organizationBackground"
                    rtfIndex={1}
                  />
                </div>
              ) : (
                <></>
              )}
              <hr className="mb-4 mt-4" />
            </div>
            <div
              className="list overflow-hidden mb-4"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Needs</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    className="ml-2"
                    onChange={setColNeeds}
                  />
                </div>
              </div>
              <hr />
              {colNeeds ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    form={form}
                    name="needs"
                    rtfIndex={2}
                  />
                </div>
              ) : (
                <></>
              )}
              <hr className="mb-4 mt-4" />
            </div>
            <div
              className="list overflow-hidden mb-24"
              style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
            >
              <div
                className=""
                style={{ background: '#00BD52', height: '10px' }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">Organizational Landscape</p>
                <div className="flex mt-2">
                  <span>
                    (Non-Aktifkan jika ingin informasi ini tidak ditampilkan)
                  </span>
                  <Switch
                    defaultChecked
                    className="ml-2"
                    onChange={setColLandscape}
                  />
                </div>
              </div>
              <hr />
              {colLandscape ? (
                <div className="mt-2 px-5">
                  <CustomQuill
                    Form={Form}
                    form={form}
                    name="organizationalLandscape"
                    rtfIndex={3}
                  />
                </div>
              ) : (
                <></>
              )}
              <hr className="mb-4 mt-4" />
            </div>

            <div
              className="mt-6 px-4"
              style={{
                borderRadius: '8px',
                border: '1px solid #EAECF0',
                background: '#FFFF',
                position: 'fixed',
                bottom: 0,
                width: 'calc(100vw - 400px)',
              }}
            >
              <div className="flex justify-between items-center">
                <Space>
                  <NavLink to={'/proposal-submission'}>
                    <Button icon={<ArrowLeftOutlined />} className="btn-back">
                      <span className="ml-2 text-center">Kembali</span>
                    </Button>
                  </NavLink>
                </Space>
                <div className="row">
                  <Form.Item>
                    <Space>
                      {/* <NavLink to={'/proposal-submission'}> */}
                      {/* <Button
                        icon={<SaveOutlined />}
                        className="btn-back"
                        onClick={() => {
                          onFinish(form.getFieldsValue(), 'list');
                        }}
                      >
                        <span className="ml-2 text-center">Simpan & Draft</span>
                      </Button> */}
                      {/* </NavLink> */}
                    </Space>
                    <Space>
                      {/* <NavLink to={'/proposal-submission/create-proposal/step-2'}> */}
                      <Button
                        htmlType="submit"
                        className="btn-submit mt-4 ml-4"

                        // onClick={() => {
                        //   let error = form.getFieldsError();

                        //   console.log(error);
                        //   onFinish(form.getFieldsValue(), "next");
                        // }}
                      >
                        <span className="ml-2 text-center">Selanjutnya</span>
                        <Icon
                          component={ArrowRightOutlined}
                          className="mr-2"
                          style={{ fontSize: 15 }}
                        />
                      </Button>
                      {/* </NavLink> */}
                    </Space>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default CreateProposalSubmissionStepOne;
